import React, { Component } from 'react';
import Layout from '../components/layout';
import Intro from '../components/mask';
import SEO from '../components/seo';
import Carousel from '../components/carousel';
import AboutSection from '../components/aboutSection'
import Card from '../components/card'
import IconBullet from '../components/iconbullet'
import BookingForm from '../components/BookingForm'
import { MDBRow, MDBCol, MDBContainer, MDBIcon, MDBBtn } from 'mdbreact'
import wd_badge from "../images/badge.png"



class App extends Component {
  render() {
    return (
      <>
        <Layout>
          <SEO title="About Me - Amanda Marriage Celebrant" keywords={[`gatsby`, `MDBReact`, `react`, `Material Design For Bootstrap`]} />

          <main>
            <MDBContainer className="my-5">
              
              <BookingForm />
            </MDBContainer>
          </main>
        </Layout>
      </>
    );
  }
}

export default App;
